import Logo from '../images/logo.jpeg';

export default function ComingSoon() {
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center brand-secondary-bg py-8">
      <img src={Logo} width={300} height={30} alt="Little Wild Childminding" />
      <h1 className="text-6xl lg:text-7xl xl:text-8xl text-gray-200 tracking-wider brand-primary-color mt-12 text-center">
        Coming Soon
      </h1>
    </div>
  );
}
