// import Header from './components/Header';
// import Section from './components/Section';
// import Features from './components/Features';
// import About from './components/About';
// import Partners from './components/Partners';
// import Footer from './components/Footer';
import ComingSoon from './components/ComingSoon';

function App() {
  return (
    <>
      <ComingSoon></ComingSoon>
    </>
  );
}

export default App;
